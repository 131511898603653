import React from 'react'
import {Box,Stack,Grid} from '@mui/material'
import ConversionButton from './Components/ConversionButton'
import AccentBox from './Components/AccentBox'

function FirstView(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const msgList=[
        {tag:"コスト",msg:"業務が属人化。退職リスク、採用コストを下げたい…"},
        {tag:"クオリティ",msg:"計算ミス、システムエラーが多く、毎月大変…"},
        {tag:"やり取り",msg:"年金事務所や労基署等、問い合わせ先が複数あって面倒…"}
    ]

    const createRedAccentSpan=(title)=>{
        return(
            <Box component="span" color="red">{title}</Box>
        )
    }

    return (
        <>
        <Box id="top" component="section" sx={
                {
                    background:`url(${baseResouceUrl+"firstView10.jpg"})`,
                    backgroundSize:"cover",
                    backgroundPositionY:"center",
                    backgroundPositionX:"center",
                    backgroundRepeat:"no-repeat",
                    
                }}>
                    <Box backgroundColor="#ffffffd4">
            <Box className='section-content' textAlign="center" pt={15} pb={10} >
                <Box component="h1" fontWeight="bold">
                    <Box fontSize={{xs:50,md:70}}>あなたの会社の給与計算、</Box>
                    <Box fontSize={{xs:50,md:70}}>
                        <Box ml={{xs:2,md:0}} display="inline-block" fontSize={{xs:30,md:40}} p={1} position="relative" color="white" backgroundColor="#136ba8" borderRadius={5}>
                            <Box component="img" src={baseResouceUrl+"firstView01.svg"} alt="Speech Bubble" loading="lazy" width={30} display="inline" position="absolute" left={-30} top={-20} />
                            <Box width={25} height={25}  sx={{clipPath:"polygon(0 0, 100% 50%, 0 100%)",transform:"translateY(50%)"}} position="absolute" backgroundColor="#136ba8" right={-20} />
                            ITに強い
                        </Box>
                        <Box component="span" ml={3} color="#136ba8">労務のプロ</Box>が代行します
                    </Box>
                </Box>

                <Stack direction={{xs:"column",md:"row"}} justifyContent="center" alignItems="center" mt={5} gap={2}>
                    <Stack alignItems="center">
                        <Stack justifyContent="center" gap={1} fontWeight="bold" fontSize={{xs:14,md:18}}>
                            {msgList.map((item,index)=>{
                                return(
                                    <Stack key={index}  >
                                        <Stack direction="row" flexWrap="wrap"  flex={1}  borderRadius={5} alignItems={{xs:"center",md:"center"}} justifyContent="flex-start" textAlign="left" gap={1}>
                                            <Stack direction="row" alignItems="center" justifyContent="flex-start" width={{xs:110,md:140}} height={30} pl={1} pr={1} gap={0.5} sx={{clipPath:"polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%)"}} color="white" backgroundColor="#136ba8" >
                                                <Box component="img" src={baseResouceUrl+"firstView02.svg"} alt="Speech Bubble" loading="lazy" width={{xs:15,md:20}} display="inline" />{item.tag}
                                            </Stack>
                                            <Box flex={1} component="h3">{item.msg}</Box> 
                                        </Stack>
                                    </Stack>
                                )
                            })}
                        </Stack>

                        
                        <Stack alignItems="center" mt={3} >
                            <Box  width={60} height={25} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#6da6d3" />
                            <ConversionButton mt={3} fontSize={{xs:25,md:30}}>無料オンライン相談</ConversionButton>
                        </Stack>
                    </Stack>
                    <Stack flex={1} alignItems="center" justifyContent="center">
                        <Box component="img" src={baseResouceUrl+"firstView03.svg"} alt="Speech Bubble" width={{xs:300,md:500}} maxWidth="100%"/>
                    </Stack>
                    
                    
                </Stack>



            </Box>
            </Box>
        </Box>
        <Box component="section" pt={10} pb={10} backgroundColor="#deebf3">
            <Box className='section-content' textAlign="center" fontWeight="bold" fontSize={{xs:25,md:30}}>
                <Box fontSize={{xs:25,md:30}} fontWeight="bold">ただの「給与計算」だけじゃない！ <AccentBox>頼れる</AccentBox>・TRIPORTの「給与計算」BPO</Box>

                <Grid container alignItems="stretch" mt={5} >
                    <Grid item xs={12} md={3} backgroundColor="#136ba8" color="white" p={3} display="flex" justifyContent="center" alignItems="center">給与計算</Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center" alignItems="center">＋</Grid>
                    <Grid item xs={12} md={8} backgroundColor="#6da6d3" p={3}>
                        <Stack direction={{xs:"column",md:"row"}} gap={2} alignItems={{xs:"normal",md:"center"}} justifyContent="space-evenly">
                            <Box backgroundColor="white" p={2} >法令遵守対応</Box>
                            <Box backgroundColor="white" p={2} >助成金活用</Box>
                            <Box backgroundColor="white" p={2}>多様な人材活用</Box>
                            <Box>等</Box>
                        </Stack>
                    </Grid>
                </Grid>

                <Box mt={5} fontSize={{xs:30,md:40}} fontWeight="bold"><AccentBox>企業利益をトータルに考慮</AccentBox>して給与計算を実施します。</Box>
            </Box>
        </Box>

        <Box component="section" sx={
            {
                background:`url(${baseResouceUrl+"firstView08.jpg"})`,
                backgroundSize:"cover",
                backgroundPositionY:"center",
                backgroundPositionX:"center",
                backgroundRepeat:"no-repeat",
            }}>
            <Box backgroundColor="#136ba8e6" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">

                    <Stack justifyContent="center" alignItems="center" >
                        <Box lineHeight={2} pl={{xs:5,md:10}} pr={{xs:5,md:10}} fontSize={{xs:20,md:25}} fontWeight="bold" color="black" backgroundColor="white" sx={{clipPath:"polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%, 5% 50%)"}}>TRIPORTの確かな実績</Box>
                    </Stack>
                    
                    <Stack color="white" alignItems="center" justifyContent="center" mt={2}>
                        <Stack direction={{xs:"column",md:"row"}} fontSize={{xs:30,md:40}} fontWeight="bold" alignItems="center">
                            <Box position="relative"><Box component="img" src={baseResouceUrl+"firstView09.svg"} alt="Speech Bubble" loading="lazy" width={30} display="inline" position="absolute" left={-35} top={-12} />
                            ITに強い</Box>
                            <Box fontSize={{xs:50,md:70}} ml={2}>労務のプロ</Box></Stack>
                    </Stack>
                    

                    <Stack direction="row" mt={2} gap={1} alignItems="center" justifyContent="center">
                        <Box component="img" src={baseResouceUrl+"firstView05.svg"} alt="Speech Bubble" loading="lazy" sx={{
                            objectFit: "cover",
                            objectPosition:"0% 0%",
                            overflow: "hidden",
                            minWidth: 60,
                            width:60,
                            height: 120
                        }}/>
                        <Stack alignItems="center" >
                            <Stack direction="row" alignItems="center" color="black" justifyContent="center" gap={0.5} fontSize={16} backgroundColor="white" borderRadius={30} p="0 32px">
                                <Box sx={{wordBreak:"keep-all"}}>後援</Box>
                                <Box flex={1} component="img" src={baseResouceUrl+"firstView06.png"} alt="Speech Bubble" loading="lazy" width="100%" maxWidth={150}/>
                                <Box>等</Box>
                            </Stack>
                            <Box mt={1} fontSize={{xs:25,md:30}} fontWeight="bold">第10回 日本HRチャレンジ大賞 奨励賞 受賞</Box>
                        </Stack>
                        <Box component="img" src={baseResouceUrl+"firstView05.svg"} alt="Speech Bubble" loading="lazy"  sx={{
                            objectFit: "cover",
                            objectPosition:"100% 0%",
                            overflow: "hidden",
                            minWidth: 60,
                            width:60,
                            height: 120
                        }}/>
                    </Stack>

                    <Stack mt={5} direction={{xs:"column",md:"row"}} gap={5}>
                        <Box flexBasis="50%" >
                            <Box component="img" src={baseResouceUrl+"firstView07.png"} alt="Speech Bubble" loading="lazy"  maxWidth="100%"/>
                            <Box fontSize={10}>※ 日本HRチャレンジ大賞は、社労士の付加価値をオンラインで提供する「クラウド社労士コモン」にて受賞。</Box>
                        </Box>
                        <Stack flexBasis="50%" backgroundColor="white" color="black" textAlign="left" p={5}>
                            <Stack justifyContent="center" alignItems="center" flex={1} >
                                <Box p={2} pl={{xs:5,md:10}} pr={{xs:5,md:10}} color="white" backgroundColor="#fd615c" fontWeight="bold" sx={{clipPath:"polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%, 10% 50%)"}}>受賞理由</Box>
                            </Stack>
                            
                            <Box fontSize={16}><AccentBox>法改正や助成⾦情報等の最新情報提供や各種労務相談、規程・帳簿等確認</AccentBox>、<br/><AccentBox>改善指導及び作成代行、助成⾦活用サポート</AccentBox>に至るまで、人事労務まわりのお悩みを解決する完全オンライン型サービス。</Box>
                            <Box mt={5} fontSize={18} fontWeight="bold">{createRedAccentSpan("ITのチカラ")}によって、全国の中小企業に対して {createRedAccentSpan("高付加価値なサービスを低料金で安定的に提供")} している点が高く評価されました。</Box>
                            
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </Box>
        </>
    )
}

export default FirstView;