import React from 'react'
import {Box,Stack} from '@mui/material'
import ConversionButton from './Components/ConversionButton'
import AccentBox from './Components/AccentBox'

function Features(){

    const baseResouceUrl=process.env.PUBLIC_URL+"/static/media/Lp/"

    const msgList=[
        {msg1:"人件費や退職リスク等", msg2:"コスト削減", image:baseResouceUrl+"features01.svg"},
        {msg1:"企業利益を考慮した", msg2:"質の高い給与計算", image:baseResouceUrl+"features02.svg"},
        {msg1:"やり取り", msg2:"簡単・安心・シンプル", image:baseResouceUrl+"features03.svg"},
    ]

    return (
        <>
        <Box id="featuers" component="section" pt={15} pb={10} >
            <Box className='section-content' textAlign="center" >
                <Box component="h1" fontSize={{xs:35,md:45}} fontWeight="bold">TRIPORT の 給与計算BPO の特徴</Box>

                
                <Stack mt={5} direction={{xs:"column",md:"row"}} justifyContent="center" gap={5} fontWeight="bold">
                    {msgList.map((item,index)=>{
                        return(
                            <Stack component="h3" flexBasis="33%" key={index} alignItems="center">

                                <Box borderRadius="100%" width={200} height={200} fontSize={80} color="#6da6d3" lineHeight={1.1} backgroundColor="#deebf3">{"0"+(index+1)}</Box>
                                <Stack width="100%" flex={1}  borderRadius={5} p={{xs:2,md:"16px 32px 32px 32px"}} alignItems="center" backgroundColor="#deebf3" mt={-13} gap={1}>
                                    <Box fontSize={{xs:18,md:20}}>{item.msg1}</Box> 
                                    <Box fontSize={{xs:25,md:30}}>{item.msg2}</Box> 
                                    <Stack flex={1} backgroundColor="white" p={2} alignItems="center" justifyContent="center" width="100%">
                                        <Box component="img" src={item.image} alt="Speech Bubble" loading="lazy" width={200} maxWidth="100%"/>
                                    </Stack>
                                </Stack>
                            </Stack>
                        )
                    })}
                </Stack>

            </Box>
        </Box>

        <Box component="section" sx={
            {
                background:`url(${baseResouceUrl+"features04.jpg"})`,
                backgroundSize:"cover",
                backgroundPositionY:"center",
                backgroundPositionX:"center",
                backgroundRepeat:"no-repeat",
                
            }}>
            

            <Box backgroundColor="#136ba8de">
                <Box width={{xs:200,md:400}} height={{xs:50,md:100}} m="0 auto" backgroundColor="white" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-2px)"}} />
                <Box className='section-content' textAlign="center" pt={10} pb={10}>
                    
                    <Stack gap={5}>
                        <Stack direction={{xs:"column-reverse",md:"row"}} gap={2} mt={2}>
                            <Box flex={1} borderRadius={10} backgroundColor="#deebf3" p={{xs:2,md:5}}>

                                <Box fontWeight="bold" fontSize={{xs:18,md:20}}>{msgList[0].msg1}</Box>
                                <Box fontWeight="bold" fontSize={{xs:25,md:30}}>{msgList[0].msg2}</Box>

                                <Stack direction={{xs:"column", md:"row"}} textAlign="left" gap={2} alignItems="center">
                                    <Stack  backgroundColor="white" p={2} justifyContent="center" alignItems="center" width={{xs:"100%",md:350}} height={{xs:200,md:350}}>
                                        <Box component="img" src={msgList[0].image} alt="Speech Bubble" loading="lazy" width={{xs:200,md:"100%"}} maxWidth={{xs:"100%",md:"auto"}} />
                                    </Stack>
                                    <Stack flex={1} pl={2} justifyContent="space-evenly" gap={2}>
                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>従来の紙媒体でのやり取り等は一切不要。</Box>
                                                <Box><AccentBox fontWeight="bold">ペーパーレス化</AccentBox>でコスト削減</Box>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>自社内で行なっていた給与計算をアウトソーシングすることで、退職コストを軽減できると共に、</Box>
                                                <AccentBox fontWeight="bold">「人件費」や「システム利用費」等を削減。</AccentBox>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>働き方の制度整備により、肥大化していた残業代などの<AccentBox fontWeight="bold">削減方法もご提案</AccentBox>。</Box>
                                                <Box fontSize={16}>（固定残業制度／フレックスタイム制度等）</Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Stack color="#deebf3" fontWeight="bold" fontSize={{xs:100,md:200}} alignItems="center" justifyContent="center">01</Stack>
                        </Stack>

                        <Stack direction={{xs:"column",md:"row"}} gap={2}>
                            <Stack color="#deebf3" fontWeight="bold" fontSize={{xs:100,md:200}} alignItems="center" justifyContent="center">02</Stack>
                            <Box flex={1} borderRadius={10} backgroundColor="#deebf3" p={{xs:2,md:5}}>

                                <Box fontWeight="bold" fontSize={{xs:18,md:20}}>{msgList[1].msg1}</Box>
                                <Box fontWeight="bold" fontSize={{xs:25,md:30}}>{msgList[1].msg2}</Box>

                                <Stack direction={{xs:"column", md:"row"}} textAlign="left" gap={2} mt={2} alignItems="center">
                                    <Stack  backgroundColor="white" p={2} justifyContent="center" alignItems="center" width={{xs:"100%",md:350}} height={{xs:200,md:350}}>
                                        <Box component="img" src={msgList[1].image} alt="Speech Bubble" loading="lazy" width={{xs:200,md:"100%"}} maxWidth={{xs:"100%",md:"auto"}}/>
                                    </Stack>
                                    <Stack flex={1} pl={2} display="flex" direction="column" justifyContent="space-evenly" gap={2}>
                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>給与計算はもちろんのこと、労務周りの知見が豊富な <AccentBox fontWeight="bold">労務のプロが給与計算代行</AccentBox>。</Box>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box><AccentBox fontWeight="bold">ITの力</AccentBox> を活用し、極限まで <AccentBox fontWeight="bold">ヒューマンエラーが起こらないしくみ</AccentBox> を構築。毎月正確に対応。</Box>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box position="relative">貴社の労務状況を加味した上で、必要に応じ「法令遵守対応」「助成金活用<Box component="span" position="absolute" fontSize={13}>※1</Box>　」「多様な人材活用<Box component="span" position="absolute" fontSize={13}>※2</Box>　」等まで、<AccentBox fontWeight="bold">企業利益を考慮</AccentBox> しアドバイス。</Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Box textAlign="left" mt={5} fontSize={13}>
                                    <Box >※1：助成金活用実績：TRIPORT独自サービス「助成金コーディネート」にて、 全国のあらゆる業種の企業様の助成金申請をサポート。受給額診断実績は20,000社以上（2024年4月1日現在）。</Box>
                                    <Box mt={2}>※2：多様な人材活用実績：自社における働き方改革について、厚生労働省『輝くテレワーク賞』特別奨励賞、総務省『テレワーク先駆者百選』、 東京都『東京ライフワークバランス認定企業』など表彰等多数。</Box>
                                </Box>
                            </Box>
                            
                        </Stack>

                        <Stack direction={{xs:"column-reverse",md:"row"}} gap={2}>
                            <Box flex={1} borderRadius={10} backgroundColor="#deebf3" p={{xs:2,md:5}}>
                                <Box fontWeight="bold" fontSize={{xs:18,md:20}}>{msgList[2].msg1}</Box>
                                <Box fontWeight="bold" fontSize={{xs:25,md:30}}>{msgList[2].msg2}</Box>
                                <Stack direction={{xs:"column", md:"row"}} textAlign="left" gap={2} mt={2}>
                                    <Stack backgroundColor="white" p={2} justifyContent="center" alignItems="center" width={{xs:"100%",md:350}} height={{xs:200,md:350}}>
                                        <Box component="img" src={msgList[2].image} alt="Speech Bubble" loading="lazy" width={{xs:200,md:"100%"}} maxWidth={{xs:"100%",md:"auto"}}/>
                                    </Stack>
                                    <Stack flex={1} pl={2} display="flex" direction="column" justifyContent="space-evenly" gap={2}>
                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box><AccentBox fontWeight="bold">チャット</AccentBox>で気軽に労務のプロに相談。やり取り簡単！</Box>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>必要に応じて<AccentBox fontWeight="bold">電話でのフォロー</AccentBox> も可。</Box>
                                                <Box>やり取り安心！</Box>
                                            </Box>
                                        </Stack>

                                        <Stack direction="row" gap={1}>
                                            <Box mt={1} width={15} height={15} borderRadius="100%" backgroundColor="#6da6d3"/>
                                            <Box flex={1} fontSize={20}>
                                                <Box>年金事務所や労基署等、複数の問い合わせ先に確認をしなくても、TRIPORTの担当者に問い合わせればOK！<AccentBox fontWeight="bold">窓口が１本化</AccentBox> され、やり取りシンプル！</Box>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Stack color="#deebf3" fontWeight="bold" fontSize={{xs:100,md:200}} alignItems="center" justifyContent="center">03</Stack>
                        </Stack>
                    </Stack>

                </Box>
            </Box>
        </Box>

        <Box component="section" pt={10} pb={10} backgroundColor="#efefef">
            
            <Box className='section-content' textAlign="center" color="white">

                <Stack direction={{xs:"column",md:"row"}} alignItems="center" justifyContent="center" gap={2}>
                    <Box width={250}/>
                    <ConversionButton fontSize={{xs:25,md:30}}>無料オンライン相談</ConversionButton>
                    <Box component="img" src={baseResouceUrl+"features05.svg"} sx={{transform:"scale(-1, 1)"}} alt="Speech Bubble" loading="lazy" width={250} />
                </Stack>
                
            </Box>
        </Box>
        </>
    )
}

export default Features;